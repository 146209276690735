import revive_payload_client_3E0HN4rR5c from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xb3vJX2Mja from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_piUES6gXkG from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_feCsykgv1q from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.2_terser@5_l5q7a52smqrigb5xtj3oo42poe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_2L4h15T34k from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Kfh2mJZBYI from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uABiJOxwau from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CwAPSEXMbK from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZOBK3OEaRH from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/.nuxt/components.plugin.mjs";
import prefetch_client_wm6dmkgUI7 from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import fontawesome_Wqhube0mUu from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@vesp+nuxt-fontawesome@1.1.0_magicast@0.3.5_rollup@4.28.1/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import nuxt_plugin_GmopPxAbej from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_fMOyHGjvbz from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_TpPc9NuPhz from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_w016SqEANS from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_VT5XQofXG2 from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typesc_tri4rgbmfq5h6n3shp7pptvllq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import base64_2hii42QBJd from "/codebuild/output/src2250774584/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  revive_payload_client_3E0HN4rR5c,
  unhead_xb3vJX2Mja,
  router_piUES6gXkG,
  _0_siteConfig_feCsykgv1q,
  payload_client_2L4h15T34k,
  navigation_repaint_client_Kfh2mJZBYI,
  check_outdated_build_client_uABiJOxwau,
  chunk_reload_client_CwAPSEXMbK,
  plugin_vue3_ZOBK3OEaRH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wm6dmkgUI7,
  plugin_OHU0vggoM9,
  fontawesome_Wqhube0mUu,
  nuxt_plugin_GmopPxAbej,
  switch_locale_path_ssr_fMOyHGjvbz,
  i18n_TpPc9NuPhz,
  plugin_w016SqEANS,
  plugin_VT5XQofXG2,
  base64_2hii42QBJd
]